<template>
  <div class="d-flex flex-column">
    <v-text-field
      class="mx-2 mb-4 filter-text"
      flat
      clearable
      autofocus
      hide-details
      :label="$t('t.Filter')"
      solo
      v-model="filterText"
      :prepend-inner-icon="$icon('i.Search')"
    />
    <div
      class="d-flex flex-row mb-4 ml-4"
      v-for="(_, i) in icons"
      :key="i"
    >
      <v-icon>{{_.value}}</v-icon>
      <span class="ml-4">{{_}}</span>
    </div>

  </div>

</template>

<script>
export default {
  computed: {
    icons () {
      return this.getIcons().filter(d => d.id.toLowerCase().includes((this.filterText ?? ''))).sort((a, b) => a.id.localeCompare(b.id))
    }
  },
  data () {
    return {
      filterText: null
    }
  },
  methods: {
  }

}
</script>

<style lang="stylus" scoped>
.filter-text
  width 400px
</style>
